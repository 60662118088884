import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AnimationIcon from '@mui/icons-material/Animation';
import Modal from '@mui/material/Modal';

import installWindows from "./assets/installWindows.png";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [aboutOpen, setAboutOpen] = React.useState(false);
  const [installOpen, setInstallOpen] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AnimationIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 3,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            PBMM
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
                <MenuItem onClick={() => {
                    setAboutOpen(true);
                    handleCloseNavMenu();
                }}>
                    <Typography textAlign="center">About</Typography>
                </MenuItem>
                <MenuItem onClick={() => {
                    setInstallOpen(true);
                    handleCloseNavMenu();
                }}>
                    <Typography textAlign="center">Install</Typography>
                </MenuItem>
            </Menu>
          </Box>
          <AnimationIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            PBMM Utility
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                onClick={() => {
                    setAboutOpen(true);
                    handleCloseNavMenu();
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                About
              </Button>
              <Button
                onClick={() => {
                    setInstallOpen(true);
                    handleCloseNavMenu();
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Install
              </Button>
          </Box>
        </Toolbar>
      </Container>
      <Modal
        open={aboutOpen}
        onClose={() => setAboutOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Phase Based Motion Magnification
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This is a web service to run the phase based motion magnification algorithm on user-submitted videos. The base web app template and the original software and infrastructure were created by <a href="https://mikel.tech/">Mikel Matticoli</a> in collaboration with Prof. Zhu Mao, Celso de Cabo, and Nicholas Valente, who developed the video cropping procedure. 
            <br />
            <br />
            This tool is a product of the <a href="https://ids.wpi.edu">Intentional Design Studio</a> at Worcester Polytechnic Institute.
            <br />
            <br />
            The original PBMM algorithm was described in a 2013 SIGGRAPH paper by Wadhwa, Rubinstein, Durand, and Freeman. 
            Our python implementation of this algorithm is adapted from <a href="https://github.com/Dhruv-Mahajan1/Phase-Based-Motion-Magnification">Dhruv Mahajan's PBMM Jupyter notebook</a>.
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={installOpen}
        onClose={() => setInstallOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Install As App
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This web app is fully functional and accessible via the browser at <a href="https://pbmm.wpi.edu/">https://pbmm.wpi.edu/</a> without installing any additional software to your device.
            <br />
            <br />
            For easy access, you can also add this app to your home screen on iOS/Android, or as an application shortcut on the Google Chrome browser on desktop. On mobile, open your browser's menu and select "Install App" (Android) or "Add to Home Screen" (iOS). On desktop, select the icon in your address bar as pictured below.
            <br />
            <br />
            <img width="100%" src={installWindows} />
          </Typography>
        </Box>
      </Modal>
    </AppBar>
  );
}
export default ResponsiveAppBar;
