// 6b12c704701661250c3d5fa2a77ae83e

import React, {useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import { Loading } from "./Loading/Loading";

export default function Job(props) {
    const jobid = useLocation().pathname.split('/')[2];
    const [progress, setProgress] = useState(<Loading />);
    const [status, setStatus] = useState(<div />);
    useEffect(() => {
      let t = setInterval(() => {
        fetch(`/api/default/job/${jobid}`).then(res => res.text()).then(
            (text) => {
                const status = (text.includes("Result Video") ? "Complete ✅" :
                // lowercase f specifically
                text.includes("failed") || text.includes("Exited") ? "Failed ❌" :  
                text.includes("processing") ? "Processing 🔁" :
                "Queued, will begin shortly ⏲️");

                const linkregex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                const regex = new RegExp(linkregex);

                const link = text.match(regex);

                setStatus(<>
                  <h4>{status}</h4>
                  {(link && link.length > 0) ? <a href={link[0]}>Click here to view result video</a> : ""}
                </>);

                setProgress(<div style={{textAlign: "left"}}>
                    {text.split("\n").map(e => {
                        return <>{e}<br/></>
                    })}
                </div>);
            }
        )
      }, 2000);
    
      return () => {
        clearInterval(t);
      }
    }, [])
    
    return <div style={{textAlign: "center"}}>
            <h3>Job Status</h3>
            <label>({jobid})</label>
            {status}
            <p style={{display: "block", height: "50vh", width: "80vw", marginLeft: "auto", marginRight: "auto", overflow: "scroll"}} >
                {progress}
            </p>
        </div>
}