import { Button, Card, Select, MenuItem, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { createRef, useEffect, useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { Loading } from './Loading/Loading';


import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import "./App.css";

const fileTypes = ["MOV", "MP4", "AVI"];
const cardStyles = {
	width: '90%',
	maxWidth: '90%',
	marginLeft: 'auto',
	marginRight: 'auto',
	marginTop: 40,
	padding: 20
};


export function App() {
	const [file, setFile] = useState(null);
	const [frame, setFrame] = useState(null);
	const [crop, setCrop] = useState(null);
	const [fps, setFps] = useState(0);
	const [params, setParams] = useState({
		fl: 0,
		fh: 0,
		fs: 1,
		alpha: 20,
		sigma: 5,
		pyramid_type: "octave",
		email: ""
	});
	const [imgProps, setImgProps] = useState({
		natWidth: 0,
		natHeight: 0,
		width: 0,
		height: 0,
	});
	const imgRef = createRef();

	const handleFileChange = (file) => {
		setFile(file);
		console.log(file);
	};

	const handleImageChange = () => {
		const i = imgRef.current;
			setImgProps({natHeight: i.naturalHeight, natWidth: i.naturalWidth, height: i.height, width: i.width});
	};

	const pyramidTypes = [
		{
		  value: 'octave',
		  label: 'Octave',
		},
		{
		  value: 'halfOctave',
		  label: 'Half Octave',
		},
		{
		  value: 'smoothHalfOctave',
		  label: 'Smooth Half Octave',
		},
		{
		  value: 'quarterOctave',
		  label: 'Quarter Octave',
		},
	  ];


	useEffect(() => {
		const f = async () => {
			if (file) {
				const formData = new FormData();
				formData.append(file.name, file);
				try {

					const res = await axios.post('/api/default/upload', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					});
					console.log(res.data);
					const hash = res.data['hash'];
					let fps = res.data['fps'];
					window.sessionStorage.setItem("job", hash);
					window.sessionStorage.setItem("fps", fps);
					setFrame(hash);
					setFps(fps);
				} catch(e) {
					window.alert("Error uploading your file. If this keeps happening, your file may be too large or incorrectly formatted");
				}
			}
		};
		f();
	}, [file]);

	// useEffect( () => {
	// 	console.log(crop);
	// }, [ crop ]);

	// useEffect( () => {
	// 	console.log(imgProps);
	// }, [ imgProps ]);

	const startJob = () => {
		try {

			console.log(params);
			const { fs, fl, fh, alpha, sigma, pyramid_type, email } = params;
			const { x, y, width, height } = crop
			if(!fs || !fl || !fh || !alpha || !sigma || !pyramid_type || !email || !width || !height) {
				window.alert("Please fill out all fields and select a crop region before submitting");
				return;
			}
			try {
				// Adjust for screen size to calculate actual crop region
				const cx = Math.round(x * imgProps.natWidth / imgProps.width),
					  cy = Math.round(y * imgProps.natHeight / imgProps.height),
					  cw = Math.round(width * imgProps.natWidth / imgProps.width),
					  ch = Math.round(height * imgProps.natHeight / imgProps.height);
				console.log("CROP REGION "+JSON.stringify({cx, cy, cw, ch}));
				const payload = {fs, fl, fh, alpha, sigma, pyramid_type, email, x: cx, y: cy, w: cw, h: ch, hash: frame};
				axios.post('/api/default/startjob/'+frame, payload).then(async (res) => {
					window.location.href = res.data;
				}).catch(e => {
					console.error(e);
					window.alert('Invalid parameters [Err 400]');
				});
			} catch(e) {
				window.alert("Invalid parameters");
				console.error(e);
			}
		} catch(e) {
			console.log(e);
			window.alert("Please fill out all fields and select a crop region before submitting");
		}
	};

	const updateParams = (u) => {
		let p = params;
		Object.keys(u).forEach(k => {
			p[k] = u[k];
		});
		setParams(p);
	}

	let controls = <div />
	if(frame != null) {
		controls = <div style={{textAlign: "left"}}>
			<br/><br/>
			<Typography variant="h5">Video region of interest:</Typography>
			<Typography variant="label"><b>Click/tap and drag to select the portion of the video you'd like to process, and set the parameters below</b></Typography>
			<br/><br/>
			<ReactCrop crop={crop} onChange={c => setCrop(c)}>
				<img src={"/api/default/show/"+frame+".png"} ref={imgRef} onLoad={handleImageChange} />
		    </ReactCrop>
			<div style={{display: "flex", flexDirection: "column", gap: 5, textAlign: "left"}}>
				<br/><br/>
				<Typography variant="h5">Sample Rate:</Typography>
				<Typography variant="label"><b>Video Framerate:</b>{fps}fps</Typography>
				<br/>
				<TextField onChange={e => updateParams({'fs': parseFloat(e.target.value)})} type="number" label="fs (sample rate, max fps/2)" variant="outlined" /><br/>
				<Typography variant="h5">Frequency Band:</Typography>
				<TextField style={{flexGrow: 1}} onChange={e => updateParams({'fl': parseFloat(e.target.value)})} type="number" label="fl (lower frequency band threshhold)" variant="outlined" /><br/>
				<TextField style={{flexGrow: 1}} onChange={e => updateParams({'fh': parseFloat(e.target.value)})} type="number" label="fh (higher frequency band threshhold)" variant="outlined" /><br/>
				<Typography variant="h5">Magnification Settings:</Typography>
				<Typography variant="label">It is recommended to start with default settings, and adjust based on results</Typography>
				<TextField defaultValue={20} onChange={e => updateParams({'alpha': parseFloat(e.target.value)})} type="number" InputProps={{min: 0, max: 100}} label="alpha (magnification factor)" variant="outlined" /><br/>
				<TextField defaultValue={5} onChange={e => updateParams({'sigma': parseFloat(e.target.value)})} type="number" label="sigma (resolution)" variant="outlined" /><br/>
				<Select defaultValue={"octave"} onChange={e => updateParams({'pyramid_type': e.target.value})} select type="text" label="Pyramid Type" variant="outlined" helperText="Please select your pyramid type" >
					{pyramidTypes.map((option) => (<MenuItem key={option.value} value={option.value}> {option.label} </MenuItem>))}
				</Select><br/>
				<Typography variant="h5">Notify on completion:</Typography>
				<TextField onChange={e => updateParams({'email': e.target.value})} type="text" label="Email" variant="outlined" /><br/>
				<br/>
				<Button variant="contained" color="primary" onClick={startJob}>Submit</Button>
				
			</div>
		</div>
	} else {
		controls = <>
			<Typography variant="h6">To begin, upload a video file</Typography>
			<div style={{width: 300, margin: 'auto' }}>
				{file == null ?
					<FileUploader handleChange={handleFileChange} name="file" types={fileTypes} />
					:
					<Loading />
				}
			</div>
		</>
	}

	return <>
		<div>
			<main>
				<Card style={{textAlign: 'center', ...cardStyles}}>
					<Typography variant="h3">Phase Based Motion Magnification</Typography>
					{controls}
					<br/>
				</Card>
				<Card style={{...cardStyles}} >
					<Typography variant="h6"><b>Usage Notes</b></Typography>
					<Typography variant="p">
						<ul>
							<li>Videos are deleted within 24 hours. Please download your result video promptly after it has been processed.</li>
							<li>Videos are downsized to 480px max height for portrait, and 480px max width for landscape images</li>
							<li>Videos longer than 30-40s may fail due to limited compute resources. Please trim your videos to the minimum required length for analysis, especially if your video was recorded at a high framerate</li>
						</ul>
					</Typography>
				</Card>
			</main>
		</div>
	</>
}