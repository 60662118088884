import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
import { App } from './App';
import Job from "./Job";
import ResponsiveAppBar from './ResponsiveAppBar';

const customTheme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#ac2b37',
        light: '#ac2b37',
      },
      secondary: {
        main: '#34383b',
        light: '#34383b',
      },
      info: {
        main: '#2196f3',
        light: '#2196f3',
      },
    },
  });

export function AppContainer() {
  return (
    <ThemeProvider theme={responsiveFontSizes(customTheme)}>
			  <ResponsiveAppBar />
        <BrowserRouter>
          <Routes>
            <Route path="/" default element={<App />} />
            <Route path="/job/:jobid" element={<Job />} />
          </Routes>
        </BrowserRouter>
    </ThemeProvider>
  );
}